<template>
  <el-dialog
    :title="base.title || '取车'"
    :visible.sync="visible"
    width="460px"
  >
    <div class="cardTop">
      <img :src="base.carLogo" alt="" />
      <div class="pdtb10 carMsg">
        <div>{{ base.rentalModelName || "" }}</div>
        <div>{{ base.vehiclePlateNumber || "" }}</div>
      </div>
    </div>
    <div class="contentBox">
      <div class="indent10">用车信息</div>
      <div class="mt20 returnCarInfo">
        <div>
          <label>取车时间：</label>
          <span>{{ base.getVehicleTime || "" }}</span>
        </div>
        <div>
          <label>约定还车时间：</label>
          <span>{{ base.returnVehicleTime || "" }}</span>
        </div>
        <div>
          <label>实际还车时间：</label>
          <span>{{ nowTime }}</span>
        </div>
        <div>
          <label>超时时长：</label>
          <span class="fs18 fw600">{{ overtime }}</span>
        </div>
        <div>
          <label>取车时里程表值：</label>
          <span class="fs18 fw600">{{ base.startDriveDistance || 0 }}</span>
        </div>
        <div class="insertBox">
          <label>还车时里程表值：</label>
          <el-input
            v-model="endDriveDistance"
            class="number-text-l"
            :min="base.startDriveDistance"
            placeholder="请输入里程表值"
            @change="setDistance"
          ></el-input>
        </div>
        <div>
          <label>用车里程数：</label>
          <span class="fs18 fw600">{{ distence || 0 }}公里</span>
        </div>
      </div>
    </div>
    <div class="mt20 text-r">
      <el-button size="small" :loading="loading" @click="handleCancel"
        >取消</el-button
      >
      <el-button
        size="small"
        type="primary"
        :loading="loading"
        @click="handleSubmit"
        >确定</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
import { returnCarPickUp } from "@/api/lib/api";
import moment from "moment";
let timeout = null;
export default {
  data() {
    return {
      visible: false,
      loading: false,
      base: {},
      nowTime: "", //当前时间
      distence: "", //用车里程
      endDriveDistance: "", //结束里程
      overtime: 0,
    };
  },
  watch: {
    nowTime(v) {
      let m2 = moment(v);
      let m1 = moment(this.base.returnVehicleTime);
      let m = m2.diff(m1, "minute");
      if (m <= 0) {
        this.overtime = "0天0小时0分钟";
        return;
      }
      let d = 0,
        h = 0,
        mm = 0;
      mm = parseInt(m % 60);
      if (m >= 60) {
        h = parseInt(m / 60);
      }
      if (h >= 24) {
        d = parseInt(h / 24);
      }
      h = h - d * 24;
      this.overtime = `${d}天${h}小时${mm}分钟`;
    },
  },
  beforeDestroy() {
    timeout && clearInterval(timeout);
    timeout = null;
  },
  methods: {
    show(row = {}) {
      this.loading = false;
      this.base = { ...row };
      timeout && clearInterval(timeout);
      this.nowTime = moment().format("YYYY-MM-DD HH:mm:ss");

      timeout = setInterval(() => {
        this.nowTime = moment().format("YYYY-MM-DD HH:mm:ss");
      }, 2000);
      this.visible = true;
    },

    //设置还车里程
    setDistance(v) {
      let start = this.base.startDriveDistance;
      let distence = 0;
      if (v < start) {
        this.$message.error("还车里程表值不能低于取车里程表值！");
      } else {
        distence = parseInt(v - start);
      }
      this.distence = distence;
    },

    //取消取车
    handleCancel() {
      this.visible = false;
      this.loading = false;
      this.base = {};
      this.nowTime = ""; //当前时间
      this.distence = ""; //用车里程
      this.endDriveDistance = ""; //结束里程
      this.overtime = 0;
      timeout && clearInterval(timeout);
      timeout = null;
    },
    //确定取车
    handleSubmit() {
      let { id: rentalOrderId, startDriveDistance } = this.base;
      let endDriveDistance = this.endDriveDistance;
      if (endDriveDistance < startDriveDistance) {
        this.$message.error("还车里程表值不能低于取车里程表值！");
        return;
      }
      let parmas = { rentalOrderId, endDriveDistance };
      this.loading = true;
      returnCarPickUp(parmas)
        .then((res) => {
          if (res.code === "SUCCESS") {
            this.$emit("finsh");
            this.handleCancel();
            return;
          }
          this.$message.error(res.msg);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.cardTop {
  text-align: center;
  img {
    width: 200px;
    height: 120px;
  }
}
.mt20 {
  margin-top: 20px;
}
.pdtb10 {
  padding: 10px 0;
}
.contentBox {
  background: #fafbff;
  padding: 10px 0;
}
.indent10 {
  text-indent: 10px;
  font-weight: 600;
  color: #333;
}
.carMsg {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.returnCarInfo {
  color: #999;
  & > div {
    display: flex;
    align-items: center;
    margin: 18px 0;
    & > label {
      width: 120px;
      text-align: right;
      flex-shrink: 0;
    }
    & > span,
    & input {
      color: #333;
    }
    & > div {
      flex: 1;
    }
  }
}
.insertBox {
  display: flex;
  align-items: center;
  & > label {
    width: 120px;
    text-align: right;
  }
  & > div {
    width: 260px;
  }
  .number-text-l {
    /deep/ input {
      text-align: left !important;
    }
  }
}
.text-r {
  text-align: right;
}
.fs18 {
  font-size: 18px;
}
.fw600 {
  font-weight: 600;
}
</style>
