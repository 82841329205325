var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.base.title || "取车",
        visible: _vm.visible,
        width: "460px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "cardTop" }, [
        _c("img", { attrs: { src: _vm.base.carLogo, alt: "" } }),
        _c("div", { staticClass: "pdtb10 carMsg" }, [
          _c("div", [_vm._v(_vm._s(_vm.base.rentalModelName || ""))]),
          _c("div", [_vm._v(_vm._s(_vm.base.vehiclePlateNumber || ""))]),
        ]),
      ]),
      _c("div", { staticClass: "contentBox" }, [
        _c("div", { staticClass: "indent10" }, [_vm._v("用车信息")]),
        _c("div", { staticClass: "mt20 returnCarInfo" }, [
          _c("div", [
            _c("label", [_vm._v("取车时间：")]),
            _c("span", [_vm._v(_vm._s(_vm.base.getVehicleTime || ""))]),
          ]),
          _c("div", [
            _c("label", [_vm._v("约定还车时间：")]),
            _c("span", [_vm._v(_vm._s(_vm.base.returnVehicleTime || ""))]),
          ]),
          _c("div", [
            _c("label", [_vm._v("实际还车时间：")]),
            _c("span", [_vm._v(_vm._s(_vm.nowTime))]),
          ]),
          _c("div", [
            _c("label", [_vm._v("超时时长：")]),
            _c("span", { staticClass: "fs18 fw600" }, [
              _vm._v(_vm._s(_vm.overtime)),
            ]),
          ]),
          _c("div", [
            _c("label", [_vm._v("取车时里程表值：")]),
            _c("span", { staticClass: "fs18 fw600" }, [
              _vm._v(_vm._s(_vm.base.startDriveDistance || 0)),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "insertBox" },
            [
              _c("label", [_vm._v("还车时里程表值：")]),
              _c("el-input", {
                staticClass: "number-text-l",
                attrs: {
                  min: _vm.base.startDriveDistance,
                  placeholder: "请输入里程表值",
                },
                on: { change: _vm.setDistance },
                model: {
                  value: _vm.endDriveDistance,
                  callback: function ($$v) {
                    _vm.endDriveDistance = $$v
                  },
                  expression: "endDriveDistance",
                },
              }),
            ],
            1
          ),
          _c("div", [
            _c("label", [_vm._v("用车里程数：")]),
            _c("span", { staticClass: "fs18 fw600" }, [
              _vm._v(_vm._s(_vm.distence || 0) + "公里"),
            ]),
          ]),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "mt20 text-r" },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small", loading: _vm.loading },
              on: { click: _vm.handleCancel },
            },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary", loading: _vm.loading },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }